//设置缓存
export function setItem(key, value) {
    localStorage.setItem(key, JSON.stringify(value));
}

// 获取缓存
export function getItem(key) {
  return JSON.parse(localStorage.getItem(key));
}

// 清除所有缓存
export function clearAll() {
    localStorage.clear();
}

//清除指定缓存
export function clearAppoint(key) {
    localStorage.removeItem(key);
}

//判断是移动端还是pc端
export function isMobile(){
    let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
    return flag;
}

export default {
    setItem,getItem,clearAll,clearAppoint,isMobile
}
