import {
  createStore
} from 'vuex'

export default createStore({
  state: {
    //----------------------PC端----------------------
    contList:[],
    collapse: false,
    material: {},

    //----------------------移动端----------------------
    sideShow:false,
    userinfo:{}
  },
  mutations: {
    //----------------------PC端----------------------
    addcontList(state, data) {
      state
        .contList
        .push(data)
    },
    // 侧边栏折叠
    handleCollapse(state, data) {
      state.collapse = data;
    },
    // 获取活动物料配置
    materialInfo(state, data) {
      state.material = data;
    },

    //----------------------移动端----------------------
    sidePopup(state, data) {
      state.sideShow = data;
    },
    setUserInfo(state, data) {
      state.userinfo = data;
      //持久化处理
      window.localStorage.setItem("muserinfo",JSON.stringify(data));
    },
  },
  actions: {},
  modules: {}
})
