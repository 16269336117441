import {
  createRouter,
  createWebHashHistory,
  createWebHistory
} from 'vue-router'
import {TOKEN,LOGIN_ROUTER} from '@/const/index.js';
import {getItem} from '@/utils/utils'

//PC端路由
const routerPC = [
  {
    path: "/",
    name: "Home",
    component: () => import("@/views/index.vue"),
    meta: {
      keywords: 'chatgpt,chat GPT,人工智能,chat,chatgpt国内版,人工智能chat gpt在线,gptchat在线工具,chat gpt在线网页版,GPT,gpt,gpt4.0,gpt免费', // 设置页面关键词
    },
  }


];



const router = createRouter({
  // history: createWebHashHistory(), //hash为页面重置跳转首页
  history: createWebHistory(),        //history为页面不重置跳转首页
  routes:routerPC
})
router.beforeEach((to, from,nuxt)=>{
  // 更新 keywords meta 标签
  const keywords = to.meta.keywords || '';
  const keywordsElement = document.querySelector('meta[name="keywords"]');
  if (keywordsElement) {
    keywordsElement.setAttribute('content', keywords);
  }

  //需要登录的路由
  if(getItem(TOKEN)){
    nuxt()
  }else{
    //跳转首页
    if(LOGIN_ROUTER.includes(to.path)){
      nuxt(from.path)
    }else{
      nuxt()
    }

  }
});
// router.onError((error) => {
//   const pattern = /Loading chunk (\d)+ failed/g;
//   const isChunkLoadFailed = error.message.match(pattern);
//   const targetPath = router.history.pending.fullPath;
//   if (isChunkLoadFailed) {
//     router.replace(targetPath);
//   }
// });
export default router
