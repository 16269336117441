import {
  createApp
} from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import installElementPlus from './plugins/element'
import '@/assets/iconfont/iconfont.css' //图标库
import '@/assets/iconfont/iconfont.js' //图标库js
require('@/assets/css/common.less') //公共样式

const app = createApp(App)

// Vant 组件库的引入
// installVant(app)
installElementPlus(app)
app.use(store).use(router).mount('#app')
